import { Component } from '@angular/core';
import { GoogleAnalyticsService } from './google-analytics.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'InquisitiveMachines';

  constructor(private googleAnalyticsService: GoogleAnalyticsService) {
    this.appendGaTrackingCode();
  }

  private appendGaTrackingCode() {
    try {
      const script = document.createElement('script');
      script.innerHTML = `
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

        ga('create', '` + environment.googleAnalyticsKey + `', 'auto');
      `;
      document.head.appendChild(script);
    } catch (ex) {
    console.error('Error appending google analytics');
    console.error(ex);
    }
  }

}

// Somewhere else we can emit a new ga event
// this.googleAnalyticsService.emitEvent("testCategory", "testAction", "testLabel", 10);
