import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { ContactComponent } from './contact/contact.component';
import { CaseStudyComponent } from './case-study/case-study.component';

const appRoutes: Routes = [
    { path: 'home', component: HomeComponent },
    { path: 'services', component: SolutionsComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'casestudy', component: CaseStudyComponent },
    { path: '**', component: HomeComponent }
  ];

  @NgModule({
    imports: [
      RouterModule.forRoot(
        appRoutes,
        // { enableTracing: true } // <-- debugging purposes only
      )
    ],
    exports: [
      RouterModule
    ]
  })

  export class AppRoutingModule {}
