import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup;
  error: string;

  constructor(private fb: FormBuilder, private afs: AngularFirestore, public snackBar: MatSnackBar) {
    this.contactForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      phone: [''],
      title: [''],
      company: [''],
      message: [''],
    });
  }

  ngOnInit() {
  }

  send(): void {
    if (this.contactForm.valid) {
      this.afs.collection('contacts').add(this.contactForm.value);
      this.contactForm.reset();
      this.snackBar.open('Message Sent', 'OK', {
        duration: 4000,
      });
    }
  }

}
