import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @ViewChild('bannerVideo', { static: true }) bannerVideo;

  constructor() {
  }

  ngOnInit() {
    const video: HTMLVideoElement = this.bannerVideo.nativeElement;

    video.muted = true;
    // video.play().catch((err) => {
    //   console.error(err);
    // });
  }

}
