import { Component, OnInit } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.css']
})
export class SolutionsComponent implements OnInit {

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'learning',
      sanitizer.bypassSecurityTrustResourceUrl('assets/SVG/DATA_SCIENCE_07.svg'));
    iconRegistry.addSvgIcon(
      'person',
      sanitizer.bypassSecurityTrustResourceUrl('assets/SVG/DATA_SCIENCE_25.svg'));
    iconRegistry.addSvgIcon(
      'data',
      sanitizer.bypassSecurityTrustResourceUrl('assets/SVG/DATA_SCIENCE_18.svg'));
    iconRegistry.addSvgIcon(
      'infinite',
      sanitizer.bypassSecurityTrustResourceUrl('assets/SVG/DATA_SCIENCE_05.svg'));
  }

  ngOnInit() {
  }

}
