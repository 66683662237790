// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  googleAnalyticsKey: 'UA-126127558-1',
  firebase: {
    apiKey: 'AIzaSyDvqlVSljOxKOeTeieu70jmyGqVSODESq4',
    authDomain: 'https://inquisitivemachines.firebaseapp.com/',
    databaseURL: 'https://inquisitivemachines.firebaseio.com',
    projectId: 'inquisitivemachines',
    storageBucket: 'inquisitivemachines.appspot.com',
    // tslint:disable-next-line:max-line-length
    messagingSenderId: 'AAAANZz2A-g:APA91bGMYGfDUM1w8eot6GuT6Ql-ZPx2jP9kEQ75Tm64MI770jtF56xtAnYU4vtprAWTVLGuVXc9pe8oG58Xjq-bAybCpqZ9gcy0zVc8vKfj06SZBGAOrBfQPey7u_0Gos4bz-tB2Kv5'
  }
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
